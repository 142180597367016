import React from "react";
import styled, { useTheme } from "styled-components";

const HR = styled.hr`
  margin: 0;
`;

const Divider = () => {
  const { secondary } = useTheme();

  return <HR color={secondary} />;
};

export default Divider;
