import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDniNdoMGlJLVmXK8tbJE8EPtd5m_TrmI0",
  authDomain: "curriculum-vitae-6a887.firebaseapp.com",
  projectId: "curriculum-vitae-6a887",
  storageBucket: "curriculum-vitae-6a887.appspot.com",
  messagingSenderId: "152623642063",
  appId: "1:152623642063:web:1ae8b34090e97da37c4eba",
  measurementId: "G-MCW7BHP2TR",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
