import cvImg from "assets/img/cv.png";
import React from "react";
import styled from "styled-components";
import { LogEvent } from "util/analytics";
import Link from "./Link";

const Container = styled.div`
  flex: 0;
  align-self: center;
  display: flex;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-color: ${({ theme }) => theme.secondary};
    border-style: solid;
    border-width: 35px 24px 35px 0;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
  }
`;

const Img = styled.img`
  height: 57px;
  padding: 6px;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.secondary};
`;

const Paragraph = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 14px;
  margin: 6px 0;
`;

const Contact = () => {
  return (
    <Container>
      <Img src={cvImg} alt="Curriculum vitae" />
      <Paragraph>
        <Link href="https://rodalgaard.dk/cv" logEvent={LogEvent.CVLink}>
          curriculum vitae
        </Link>
        <Link href="https://rodalgaard.dk/cv" logEvent={LogEvent.CVLink}>
          https://rodalgaard.dk/cv
        </Link>
        <Link href="mailto:mrodalgaard@gmail.com" logEvent={LogEvent.MailLink}>
          mrodalgaard@gmail.com
        </Link>
        <Link href="tel:+4528491024" logEvent={LogEvent.PhoneLink}>
          +45 28 49 10 24
        </Link>
      </Paragraph>
    </Container>
  );
};

export default Contact;
