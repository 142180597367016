import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import analytics, { LogEvent } from "util/analytics";

const StyledLink = styled.a`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  padding: 4px;
  transition: color 0.5s ease;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

interface IProps {
  href?: string;
  icon: IconProp;
  logEvent?: LogEvent;
  onClick?: () => void;
}

const IconButton = ({ href, icon, logEvent, onClick }: IProps) => {
  const onLinkClick = () => {
    logEvent && analytics.logEvent(logEvent);
    onClick && onClick();
  };

  return (
    <StyledLink href={href} onClick={onLinkClick}>
      <FontAwesomeIcon icon={icon} />
    </StyledLink>
  );
};

export default IconButton;
