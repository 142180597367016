import React, { ReactNode, useReducer } from "react";
import { ActionType } from "store/actions";
import reducer from "store/reducer";
import { ThemeProvider } from "styled-components";
import createPersistedReducer from "use-persisted-reducer";
import { LOCALSTORAGE_KEY } from "util/constants";
import theme from "util/theme";
import AppContext, { initialState } from "./AppContext";

const usePersistedReducer: typeof useReducer = createPersistedReducer(
  LOCALSTORAGE_KEY
);

interface IProps {
  children: ReactNode;
}

const AppProvider = ({ children }: IProps) => {
  // Persist state to localstorage after reducer has been called
  const [state, dispatch] = usePersistedReducer(reducer, initialState);

  // Inject dispatch actions into provider value
  const value = {
    ...state,
    updateColor: (color: string) => {
      dispatch({
        payload: color,
        type: ActionType.UPDATE_COLOR,
      });
    },
  };

  // Update theme colors according to context state
  const updatedTheme = { ...theme, secondary: state.color };

  return (
    <AppContext.Provider value={value}>
      <ThemeProvider theme={updatedTheme}>{children}</ThemeProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
