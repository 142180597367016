import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
`;

const Intro = () => {
  return (
    <Container>
      <p>
        Hi, I am a frontend engineer and I like to create! My <i>current</i>{" "}
        favorite tool for this is React to create web applications and hybrid
        mobile apps.
      </p>
      <p>
        With 10 years of professional experience on diverging projects and
        technologies I have a deep understanding of how the full stack operates
        and how projects should be driven. I have developed systems and IoT
        solutions for several major danish companies and been responsible for
        customer applications running in production today. I also contribute to
        open source projects.
      </p>
      <p>
        Most often I am assigned the role of technical leader and architect on
        projects, but I am also a team player. I strive for a job that offers me
        variation and new challenges.
      </p>
    </Container>
  );
};

export default Intro;
