import React, { useContext } from "react";
import { ColorResult, TwitterPicker } from "react-color";
import styled from "styled-components";
import AppContext from "./AppContext";

const Container = styled.div`
  position: absolute;
  z-index: 2;
  top: 58px;
  right: calc(50% - 338px);
`;

const ColorPickerContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

interface IProps {
  handleClose: () => void;
}

const ColorPicker = ({ handleClose }: IProps) => {
  const { color, updateColor } = useContext(AppContext);

  const changeColor = (
    pickedColor: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();
    updateColor && updateColor(pickedColor.hex);
  };

  return (
    <Container>
      <ColorPickerContainer onClick={handleClose} />
      <TwitterPicker
        color={color}
        onChange={changeColor}
        triangle="top-right"
      />
    </Container>
  );
};

export default ColorPicker;
