import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import React from "react";
import Column from "./Column";
import ColumnEvent from "./ColumnEvent";

const Projects = () => {
  return (
    <Column title="Projects" icon={faTasks}>
      <ColumnEvent title="Project C" subtitle="2019-2021">
        Trifork customer project under NDA. Two large scale Angular applications
        using NGRX state management, Swagger Codegen, Offline access and Cypress
        E2E tests.
      </ColumnEvent>
      <ColumnEvent title="Project B" subtitle="2018-2019">
        Trifork customer project under NDA. End user iOS app for sharing images
        using P2P, Protobuf, Realm and Firebase.
      </ColumnEvent>
      <ColumnEvent title="Github Atom" subtitle="2015-2018">
        A hackable text editor for the 21st Century. Contributed improvements to
        the main branch and developed well-used extensions.
      </ColumnEvent>
      <ColumnEvent title="Project A" subtitle="2016-2017">
        Trifork customer project under NDA. End user iOS app for IoT devices.
      </ColumnEvent>
    </Column>
  );
};

export default Projects;
