import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import React from "react";
import Column from "./Column";
import ColumnEvent from "./ColumnEvent";

const Timeline = () => {
  return (
    <Column title="Timeline" icon={faCalendarAlt}>
      <ColumnEvent title="Trifork A/S" subtitle="2016 - present">
        Software Pilot. Frontend tech lead and solution architect. Including
        large scale projects for Vestas, Danfoss, Grundfos, Telenor, Velux and
        LEGO.
      </ColumnEvent>
      <ColumnEvent title="Nabto ApS" subtitle="2012 - 2016">
        Software Developer. Porting to new embedded platforms and building
        customer web/hybrid applications. Also responsible for all Nabto mobile
        apps.
      </ColumnEvent>
      <ColumnEvent title="Aarhus University" subtitle="2012">
        Business Engineer. A compact specialisation targeted project management
        and strategy.
      </ColumnEvent>
      <ColumnEvent title="Eng. College of Aarhus" subtitle="2008 - 2012">
        Electrical Engineer specialised in embedded systems including
        microcontrollers, Internet technology and smartphone applications.
      </ColumnEvent>
    </Column>
  );
};

export default Timeline;
