const theme = {
  primary: "#000000",
  secondary: "#1abc9c",
  grey: "#b6b6b6",
  white: "#ffffff",

  // A4 size
  size: {
    height: "297mm",
    width: "210mm",
  },
};

export default theme;
