import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons/faBirthdayCake";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import meImg from "assets/img/me.png";
import React from "react";
import styled from "styled-components";
import IconText from "./IconText";

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Img = styled.img`
  max-width: 90px;
  min-height: 90px;
  margin-right: 5px;
`;

const NameHeader = styled.h1`
  color: ${({ theme }) => theme.secondary};
  margin: 0;
`;

const Subtitle = styled.h4`
  margin: 3px 0 7px;
  text-align: center;
`;

const SubtitleTag = styled.span`
  color: ${({ theme }) => theme.grey};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Profile = () => {
  return (
    <Container>
      <Img src={meImg} width="90" height="90" alt="Profile image" />
      <TextContainer>
        <NameHeader>MARTIN RODALGAARD</NameHeader>
        <Subtitle>
          <SubtitleTag>#</SubtitleTag> Frontend Developer &amp; Embedded
          Engineer
        </Subtitle>
        <Row>
          <IconText icon={faBirthdayCake} text="34 years" />
          <IconText icon={faMapMarkerAlt} text="Risskov, Denmark" />
        </Row>
      </TextContainer>
    </Container>
  );
};

export default Profile;
