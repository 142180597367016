import React from "react";
import styled from "styled-components";
import Contact from "./Contact";
import Profile from "./Profile";

const Container = styled.div`
  margin: 8px 0 16px;
  display: flex;
`;

const TopSection = () => {
  return (
    <Container>
      <Profile />
      <Contact />
    </Container>
  );
};

export default TopSection;
