import React from "react";
import styled from "styled-components";
import Projects from "./Projects";
import Skills from "./Skills";
import Timeline from "./Timeline";

const Container = styled.div`
  margin-top: 16px;
  display: flex;

  & > *:not(:last-child) {
    margin-right: 15px;
  }
`;

const BottomSection = () => {
  return (
    <Container>
      <Skills />
      <Projects />
      <Timeline />
    </Container>
  );
};

export default BottomSection;
