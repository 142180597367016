import React from "react";
import AppProvider from "./AppProvider";
import BottomSection from "./BottomSection";
import Divider from "./Divider";
import Footer from "./Footer";
import Header from "./Header";
import MiddleSection from "./MiddleSection";
import Paper from "./Paper";
import TopSection from "./TopSection";

const App = () => {
  return (
    <AppProvider>
      <Paper>
        <Header />
        <TopSection />
        <Divider />
        <MiddleSection />
        <Divider />
        <BottomSection />
        <Footer />
      </Paper>
    </AppProvider>
  );
};

export default App;
