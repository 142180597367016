import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100;

  border: 2px solid ${({ theme }) => theme.secondary};
  border-radius: 2px;
`;

const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  padding: 6px 8px;

  svg {
    font-size: 20px;
    padding-bottom: 2px;
  }
`;

const Title = styled.h2`
  flex: 1;
  margin: 0;
  text-transform: uppercase;
`;

interface IProps {
  children: React.ReactNode;
  title: string;
  icon?: IconProp;
}

const Column = ({ title, children, icon }: IProps) => {
  return (
    <Container>
      <ColumnHeader>
        <Title>{title}</Title>
        {icon && <FontAwesomeIcon icon={icon} />}
      </ColumnHeader>
      {children}
    </Container>
  );
};

export default Column;
