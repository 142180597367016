import { faTrophy } from "@fortawesome/free-solid-svg-icons/faTrophy";
import React from "react";
import Column from "./Column";
import ColumnEvent from "./ColumnEvent";

const Skills = () => {
  return (
    <Column title="Skills" icon={faTrophy}>
      <ColumnEvent subtitle="Languages">
        TypeScript, HTML/CSS, Swift, ObjC and Java.
      </ColumnEvent>
      <ColumnEvent subtitle="Platforms">
        iOS, Android, Cordova, React Native and Web (React, Angular).
      </ColumnEvent>
      <ColumnEvent subtitle="Capabilities">
        CI/CD setup, Environments, Docker, Gitflow, Swagger, Azure Portal.
      </ColumnEvent>
      <ColumnEvent subtitle="Roles">
        Technical Lead, Architect, Domain Expert, Problem Solver and Dialog with
        Product Owner.
      </ColumnEvent>
      <ColumnEvent subtitle="I am NOT">
        Designer, UX Expert, Fullstack Engineer (noone fully is), Website
        Creator.
      </ColumnEvent>
    </Column>
  );
};

export default Skills;
