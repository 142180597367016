import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faEyeDropper } from "@fortawesome/free-solid-svg-icons/faEyeDropper";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import React, { useState } from "react";
import styled from "styled-components";
import { LogEvent } from "util/analytics";
import ColorPicker from "./ColorPicker";
import IconButton from "./IconButton";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;

  @media print {
    & > * {
      display: none !important;
    }
  }
`;

const Header = () => {
  const [showColorPicker, updateShowColorPicker] = useState(false);

  const onColorClick = () => {
    updateShowColorPicker(true);
  };

  const handleClose = () => {
    updateShowColorPicker(false);
  };

  return (
    <Container className="hidden-print">
      <IconButton
        icon={faEyeDropper}
        onClick={onColorClick}
        logEvent={LogEvent.ColorPickerClick}
      />
      <IconButton
        href="Martin_Rodalgaard_CV.pdf"
        icon={faDownload}
        logEvent={LogEvent.DownloadLink}
      />
      <IconButton
        href="https://rodalgaard.dk"
        icon={faHome}
        logEvent={LogEvent.HomeLink}
      />
      {showColorPicker && <ColorPicker handleClose={handleClose} />}
    </Container>
  );
};

export default Header;
