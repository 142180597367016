import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons/faGraduationCap";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import React from "react";
import styled from "styled-components";
import IconText from "./IconText";

const Container = styled.div`
  flex: 0 0 290px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 20px;
`;

const Keywords = () => {
  return (
    <Container>
      <IconText text="Honest, Accountable &amp; Structured" icon={faStar} />
      <IconText
        text="B.Eng. in Electrical &amp; Business"
        icon={faGraduationCap}
      />
      <IconText
        text="Hacking, Running, Travelling &amp; Renovating"
        icon={faThumbsUp}
      />
      <IconText text="Danish &amp; English" icon={faComment} />
      <IconText
        text="mrodalgaard"
        icon={faGithub}
        href="https://github.com/mrodalgaard"
      />
    </Container>
  );
};

export default Keywords;
