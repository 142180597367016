import { createContext } from "react";
import theme from "util/theme";

export interface IAppState {
  color: string;
  updateColor?: (color: string) => void;
}

export const initialState: IAppState = {
  color: theme.secondary,
};

const AppContext = createContext(initialState);

export default AppContext;
