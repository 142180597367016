import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 8px 8px 0 0;
`;

const Top = styled.div`
  display: flex;
  border-top: 2px solid ${({ theme }) => theme.grey};
`;

const Title = styled.h3`
  flex: 1;
  text-align: right;
  margin: 2px 0 0 0;
`;

const Subtitle = styled.p`
  background-color: ${({ theme }) => theme.grey};
  flex: 0;
  align-self: baseline;
  margin: 0;
  padding: 4px 8px;
  white-space: nowrap;
`;

const Body = styled.p`
  margin: 8px;
  margin-right: 0;
`;

interface IProps {
  children?: ReactNode;
  subtitle?: string;
  title?: string;
}

const ColumnEvent = ({ children, subtitle, title }: IProps) => {
  return (
    <Container>
      <Top>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
      </Top>
      <Body>{children}</Body>
    </Container>
  );
};

export default ColumnEvent;
