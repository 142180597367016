import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: flex-end;
  align-items: flex-end;
  font-weight: bold;
  margin-bottom: 30px;
`;

const CurrentPage = styled.p`
  padding: 2px;
  border-right: 2px solid ${({ theme }) => theme.secondary};
`;

const NumberOfPages = styled.p`
  padding: 2px;
`;

const Footer = () => {
  return (
    <Container>
      <CurrentPage>1</CurrentPage>
      <NumberOfPages>1</NumberOfPages>
    </Container>
  );
};

export default Footer;
