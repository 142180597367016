import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primary};

  & > svg {
    margin-right: 6px;
  }

  & > p {
    margin: 0;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;

  & > div {
    transition: color 0.5s ease;

    &:hover {
      color: ${({ theme }) => theme.secondary};
    }
  }
`;

interface IProps {
  icon?: IconProp;
  text: string;
  href?: string;
}

const IconText = ({ icon, text, href }: IProps) => {
  const content = (
    <Container>
      {icon && <FontAwesomeIcon icon={icon} />}
      <p>{text}</p>
    </Container>
  );

  return href ? <StyledLink href={href}>{content}</StyledLink> : content;
};

export default IconText;
