import React from "react";
import styled from "styled-components";
import analytics, { LogEvent } from "util/analytics";

const StyledLink = styled.a`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  font-weight: bold;
  transition: color 0.5s ease;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

interface IProps {
  children: React.ReactNode;
  className?: any;
  href: string;
  logEvent?: LogEvent;
}

const Link = ({ children, className, href, logEvent }: IProps) => {
  const onClick = () => {
    logEvent && analytics.logEvent(logEvent);
  };

  return (
    <StyledLink className={className} href={href} onClick={onClick}>
      {children}
    </StyledLink>
  );
};

export default Link;
