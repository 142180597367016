import React from "react";
import styled from "styled-components";
import Intro from "./Intro";
import Keywords from "./Keywords";

const Container = styled.div`
  display: flex;
`;

const MiddleSection = () => {
  return (
    <Container>
      <Intro />
      <Keywords />
    </Container>
  );
};

export default MiddleSection;
