import noteImg from "assets/img/note.jpg";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  height: ${({ theme }) => theme.size.height};
  width: ${({ theme }) => theme.size.width};
  margin: 10px auto;
  padding: 4px 14px;
  overflow: hidden;

  background-color: #fff;
  background: url(${noteImg});
  transition: box-shadow 0.75s ease;

  @media print {
    background: none;
  }

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;

interface IProps {
  children: React.ReactNode;
}

const Paper = ({ children }: IProps) => {
  return <Container>{children}</Container>;
};

export default Paper;
